import { Trans } from "@lingui/macro"

import { useAuctionRecordsQuery } from "@/domains/auctions/query"
import { AuctionsSlider } from "@/domains/auctions/view/slider"
import { Box } from "@/ui/box"
import { Container } from "@/ui/container"
import { Title } from "@/ui/text"

export function AuctionRecords() {
  const auctionRecordsQuery = useAuctionRecordsQuery()

  return (
    <Box as="section" css={{ py: "$16" }}>
      <Container>
        <Title as="h2" css={{ mb: "$8" }}>
          <Trans id="home.auctionRecords.title">Aukční rekordy</Trans>
        </Title>
      </Container>
      {auctionRecordsQuery.data && (
        <AuctionsSlider auctions={auctionRecordsQuery.data} />
      )}
    </Box>
  )
}
