import { t } from "@lingui/macro"
import { AnimatePresence, motion } from "framer-motion"
import { AriaRole, ReactNode, useRef, useState } from "react"
import ArrowLeftIcon from "remixicon-react/ArrowLeftLineIcon"
import ArrowRightIcon from "remixicon-react/ArrowRightLineIcon"

import { styled } from "@/stitches"
import { Box } from "@/ui/box"
import { IconButton } from "@/ui/button"
import { Stack } from "@/ui/stack"

type ScrollPosition = "start" | "middle" | "end"

const StyledEdge = styled(motion.div, {
  position: "absolute",
  top: 0,
  bottom: 0,
  width: 80,
  zIndex: 20,
  display: "grid",
  placeItems: "center",

  "@lg": {
    width: 100,
    ".dark-theme &": {
      background:
        "linear-gradient(to var(--gradient-direction), $transparent, $gray1)",
    },
  },

  variants: {
    position: {
      start: {
        left: 0,
        "--gradient-direction": "left",
      },
      end: {
        right: 0,
        "--gradient-direction": "right",
      },
    },
  },
})

function Edge({
  position,
  onNavClick,
  label,
}: {
  position: "start" | "end"
  onNavClick: () => void
  label: string
}) {
  return (
    <StyledEdge
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      position={position}
    >
      <IconButton
        variant="solid"
        shadow
        onClick={onNavClick}
        aria-label={label}
      >
        {position === "start" ? (
          <ArrowLeftIcon size="1.5em" />
        ) : (
          <ArrowRightIcon size="1.5em" />
        )}
      </IconButton>
    </StyledEdge>
  )
}

export function Slider({
  children,
  role,
}: {
  children: ReactNode
  role?: AriaRole
}) {
  const scrollableRef = useRef<HTMLDivElement | null>(null)
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>("start")

  const onScroll = () => {
    const el = scrollableRef.current
    if (el) {
      if (el.scrollLeft >= el.scrollWidth - el.offsetWidth) {
        setScrollPosition("end")
      } else if (el.scrollLeft <= 0) {
        setScrollPosition("start")
      } else {
        setScrollPosition("middle")
      }
    }
  }

  const onNavClick = (direction: -1 | 1) => () => {
    const el = scrollableRef.current
    if (el) {
      el.scrollTo({
        left: el.scrollLeft + el.clientWidth * 0.8 * direction,
        behavior: "smooth",
      })
    }
  }

  return (
    <Box role={role} css={{ position: "relative" }}>
      <AnimatePresence>
        {scrollPosition !== "start" && (
          <Edge
            position="start"
            onNavClick={onNavClick(-1)}
            label={t({
              id: "slider.previousButton.label",
              message: "Předchozí",
            })}
          />
        )}
      </AnimatePresence>
      <Stack
        css={{
          overflowY: "auto",
          pb: "$4",
          "--container-width": "var(--sizes-2xl)",
          px: "calc((var(--viewport-width) - var(--container-width)) / 2)",
        }}
        ref={scrollableRef}
        onScroll={onScroll}
      >
        <Box css={{ px: "$6" }}>{children}</Box>
      </Stack>
      <AnimatePresence>
        {scrollPosition !== "end" && (
          <Edge
            position="end"
            onNavClick={onNavClick(1)}
            label={t({
              id: "slider.nextButton.label",
              message: "Následující",
            })}
          />
        )}
      </AnimatePresence>
    </Box>
  )
}
