import { t } from "@lingui/macro"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"
import ArrowLeftIcon from "remixicon-react/ArrowLeftLineIcon"
import ArrowRightIcon from "remixicon-react/ArrowRightLineIcon"

import { AuctionPreview } from "@/domains/auctions/model"
import { AuctionCard } from "@/domains/auctions/view/card"
import { styled } from "@/stitches"
import { Box } from "@/ui/box"
import { IconButton } from "@/ui/button"

const NavButtonStyled = styled(IconButton, {
  top: "calc(50% - 20px)",
  position: "absolute",
  zIndex: 2,

  variants: {
    direction: {
      next: {
        right: "10px",
      },
      previous: {
        left: "10px",
      },
    },
  },
})

const CardWrapper = styled(motion.div, {
  width: 300,
  "@sm": {
    width: 360,
  },
  "@md": {
    width: 400,
  },
  variants: {
    position: {
      current: {},
      next: {},
    },
  },
})

const transition = {
  x: { type: "spring", stiffness: 300, damping: 30 },
}

function NavButton({
  direction,
  onClick,
}: {
  direction: "next" | "previous"
  onClick: () => void
}) {
  const Icon = direction === "previous" ? ArrowLeftIcon : ArrowRightIcon

  const label =
    direction === "previous"
      ? t({
          id: "home.carousel.previousButton.label",
          message: "Předchozí aukce",
        })
      : t({
          id: "home.carousel.nextButton.label",
          message: "Následující aukce",
        })

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <NavButtonStyled
        direction={direction}
        variant="solid"
        shadow
        onClick={onClick}
        aria-label={label}
      >
        <Icon size="1.5em" />
      </NavButtonStyled>
    </motion.div>
  )
}

const swipeConfidenceThreshold = 10000

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

export function Carousel({
  auctions,
  onPageChange,
}: {
  auctions: Array<AuctionPreview>
  onPageChange: (page: number) => void
}) {
  const [[page, direction], setPage] = useState([0, 0])
  const currentAuction = auctions[page]
  const nextAuction = auctions[page + 1]

  const hasPrevious = page > 0
  const hasNext = page < auctions.length - 1

  const paginate = (newDirection: number) => {
    const newPage = page + newDirection
    setPage([newPage, newDirection])
    onPageChange(newPage)
  }

  return (
    <Box
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      css={{
        position: "relative",
        minHeight: "500px",
      }}
    >
      <AnimatePresence>
        {currentAuction && (
          <CardWrapper
            key={currentAuction.id}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x)

              if (swipe < -swipeConfidenceThreshold && hasNext) {
                paginate(1)
              } else if (swipe > swipeConfidenceThreshold && hasPrevious) {
                paginate(-1)
              }
            }}
            initial={{
              x: direction === 0 ? "50%" : "-100%",
              scale: 0.6,
              opacity: 0,
            }}
            animate={{
              x: "-50%",
              scale: 1,
              opacity: 1,
            }}
            exit={{
              x: "-100%",
              scale: 0.6,
              opacity: 0,
            }}
            style={{
              position: "absolute",
              zIndex: 2,
              left: "50%",
              top: "50%",
              y: "-50%",
            }}
            transition={transition}
          >
            <AuctionCard auction={currentAuction} />
          </CardWrapper>
        )}
        {nextAuction && (
          <CardWrapper
            key={nextAuction.id}
            initial={{
              scale: 0.6,
              opacity: 0,
              x: "100%",
            }}
            animate={{
              scale: 0.6,
              opacity: 0.5,
              x: "50%",
            }}
            exit={{
              scale: 0.6,
              opacity: 0,
              x: "100%",
            }}
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              y: "-50%",
            }}
            transition={transition}
          >
            <AuctionCard auction={nextAuction} />
          </CardWrapper>
        )}
        {hasPrevious && (
          <NavButton
            key="previous-button"
            direction="previous"
            onClick={() => paginate(-1)}
          />
        )}
        {hasNext && (
          <NavButton
            key="next-button"
            direction="next"
            onClick={() => paginate(1)}
          />
        )}
      </AnimatePresence>
    </Box>
  )
}
