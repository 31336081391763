import * as D from "io-ts/Decoder"

import { LocalizedString } from "../localized-strings/model"

export const AppConfig = D.struct({
  appSettings: D.struct({
    homepageMainSection: D.struct({
      title: LocalizedString,
      subtitle: LocalizedString,
    }),
  }),
})

export interface AppConfig extends D.TypeOf<typeof AppConfig> {}
