/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import CryptoIcon from "remixicon-react/BitCoinLineIcon";
import ServicesIcon from "remixicon-react/CustomerService2LineIcon";
import GlobeIcon from "remixicon-react/GlobalLineIcon";
import SellIcon from "remixicon-react/HandCoinLineIcon";
import PercentIcon from "remixicon-react/PercentLineIcon";
import VipIcon from "remixicon-react/VipCrownLineIcon";
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      p: "p"
    }, props.components), {Item} = _components;
    if (!Item) _missingMdxReference("Item", true);
    return _jsxs(_Fragment, {
      children: [_jsx(Item, {
        title: "International art",
        icon: GlobeIcon,
        children: _jsx(_components.p, {
          children: "Our partnerships and branches all over Europe allow us to prepare auctions\nwith both Czech and international art."
        })
      }), "\n", _jsx(Item, {
        title: "A wide range of services",
        icon: ServicesIcon,
        children: _jsx(_components.p, {
          children: "We offer other services along with the auction portal, such as shipping of\nart, assessment and valuation of art, and our consulting services."
        })
      }), "\n", _jsx(Item, {
        title: "We accept cryptocurrencies",
        icon: CryptoIcon,
        children: _jsx(_components.p, {
          children: "We accept cash payment and payment by bank transfer in several currencies, as\nwell as cryptocurrencies such as BTC, ETH, LTC, XRP and more."
        })
      }), "\n", _jsx(Item, {
        title: "Commission and post-auction sale",
        icon: SellIcon,
        children: _jsx(_components.p, {
          children: "In addition to online auctions of art, we offer the option of buying exhibited\nitems in post-auction and commission sale for a fixed price."
        })
      }), "\n", _jsx(Item, {
        title: "The lowest commission on the market",
        icon: PercentIcon,
        children: _jsx(_components.p, {
          children: "Sellers pay the lowest auction commission on the market based on the selling\nprice of the art, with a maximum commission of 10%."
        })
      }), "\n", _jsx(Item, {
        title: "Private VIP auctions",
        icon: VipIcon,
        children: _jsx(_components.p, {
          children: "AIG offers the chance of participating in private auctions of selected\nvaluable pieces of art."
        })
      })]
    });
  }
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
