/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import CryptoIcon from "remixicon-react/BitCoinLineIcon";
import ServicesIcon from "remixicon-react/CustomerService2LineIcon";
import GlobeIcon from "remixicon-react/GlobalLineIcon";
import SellIcon from "remixicon-react/HandCoinLineIcon";
import PercentIcon from "remixicon-react/PercentLineIcon";
import VipIcon from "remixicon-react/VipCrownLineIcon";
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      p: "p"
    }, props.components), {Item} = _components;
    if (!Item) _missingMdxReference("Item", true);
    return _jsxs(_Fragment, {
      children: [_jsx(Item, {
        title: "Internationales Kunstangebot",
        icon: GlobeIcon,
        children: _jsx(_components.p, {
          children: "Dank unserer Partnerschaft und Filialen und Niederlassungen in ganz Europa\nsind wir in der Lage, regelmäßige Auktionen mit Kunstwerken aus Tschechien und\nder ganzen Welt vorzubereiten."
        })
      }), "\n", _jsx(Item, {
        title: "Breites Leistungsangebot",
        icon: ServicesIcon,
        children: _jsx(_components.p, {
          children: "Zusammen mit dem Auktionsportal können Sie viele verwandte Leistungen nutzen,\nwie z.B. Transport von Kunstwerken, Begutachtung und Bewertung von Kunstwerken\noder unsere Beratungsdienste."
        })
      }), "\n", _jsx(Item, {
        title: "Wir akzeptieren Kryptowährungen",
        icon: CryptoIcon,
        children: _jsx(_components.p, {
          children: "Bei uns können Sie nicht nur bar und per Banküberweisung in verschiedenen\nWährungen bezahlen, sondern auch mit Kryptowährungen, wie beispielsweise BTC,\nETH, LTC, XRP und anderen."
        })
      }), "\n", _jsx(Item, {
        title: "Kommissions- und Nach-Auktions-Verkauf",
        icon: SellIcon,
        children: _jsx(_components.p, {
          children: "Neben der Online-Auktion von Kunstwerken bieten wir auch die Möglichkeit an,\nausgestellte Werke im Kommissions- und Nach-Auktions-Verkauf zum Festpreis zu\nerwerben."
        })
      }), "\n", _jsx(Item, {
        title: "Niedrigste Provisionen auf dem Markt",
        icon: PercentIcon,
        children: _jsx(_components.p, {
          children: "Der Verkäufer zahlt bei uns die niedrigste Auktionsprovision auf dem Markt\nbasierend auf dem Verkaufspreis des Werkes, maximal jedoch 10%."
        })
      }), "\n", _jsx(Item, {
        title: "VIP-Privatauktion",
        icon: VipIcon,
        children: _jsx(_components.p, {
          children: "AIG bietet Ihnen die Möglichkeit, an Privatauktionen mit ausgewählten\nwertvollen Kunstwerken teilzunehmen."
        })
      })]
    });
  }
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
