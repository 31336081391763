import { Trans } from "@lingui/macro"
import { motion } from "framer-motion"
import { ReactNode } from "react"
import { RemixiconReactIconComponentType } from "remixicon-react"

// TODO
import ContentCs from "@/docs/cs/why-us.mdx"
import ContentDe from "@/docs/de/why-us.mdx"
import ContentEn from "@/docs/en/why-us.mdx"
import { useI18n } from "@/lib/i18n"
import { Box } from "@/ui/box"
import { Container } from "@/ui/container"
import { Grid } from "@/ui/grid"
import { Text } from "@/ui/text"

function Item(props: {
  title: ReactNode
  icon: RemixiconReactIconComponentType
  children: ReactNode
}) {
  const { title, children, icon: Icon } = props

  return (
    <Box
      as={motion.li}
      variants={{
        initial: { opacity: 0, y: 40 },
        visible: { opacity: 1, y: 0 },
      }}
      css={{
        display: "grid",
        gridTemplateRows: "auto auto 1fr",
        gridTemplateColumns: "auto 1fr",
        rowGap: "$4",
        columnGap: "$6",
        "& svg": {
          width: 32,
          height: 32,
        },
      }}
    >
      <Text
        as="h3"
        color="primary"
        size="2xl"
        weight="bold"
        css={{
          gridRow: 1,
          gridColumn: 2,
        }}
      >
        {title}
      </Text>
      <Text
        color="muted"
        size="lg"
        css={{
          gridRow: 2,
          gridColumn: 2,
        }}
      >
        {children}
      </Text>
      <Box
        css={{
          gridRow: 1,
          gridColumn: 1,
        }}
      >
        <Icon size="1.5em" />
      </Box>
    </Box>
  )
}

export function Values() {
  const { i18n } = useI18n()

  const Content = {
    cs: ContentCs,
    en: ContentEn,
    de: ContentDe,
  }[i18n.locale]

  return (
    <Box as="section" css={{ py: "$16" }}>
      <Container>
        <Text as="h2" size="4xl" weight="bold" css={{ mb: "$16" }}>
          <Trans id="home.whyUs.title">Proč dražit u nás?</Trans>
        </Text>
        <Grid
          as={motion.ul}
          initial="initial"
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            initial: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1,
              },
            },
          }}
          columns={{ "@initial": 1, "@md": 2, "@lg": 3 }}
          columnGap={12}
          rowGap={{ "@initial": 12, "@md": 16 }}
        >
          {Content && <Content components={{ Item }} />}
        </Grid>
      </Container>
    </Box>
  )
}
