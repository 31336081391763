/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import CryptoIcon from "remixicon-react/BitCoinLineIcon";
import ServicesIcon from "remixicon-react/CustomerService2LineIcon";
import GlobeIcon from "remixicon-react/GlobalLineIcon";
import SellIcon from "remixicon-react/HandCoinLineIcon";
import PercentIcon from "remixicon-react/PercentLineIcon";
import VipIcon from "remixicon-react/VipCrownLineIcon";
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, {})
  })) : _createMdxContent();
  function _createMdxContent() {
    const _components = Object.assign({
      p: "p"
    }, props.components), {Item} = _components;
    if (!Item) _missingMdxReference("Item", true);
    return _jsxs(_Fragment, {
      children: [_jsx(Item, {
        title: "Mezinárodní nabídka umění",
        icon: GlobeIcon,
        children: _jsx(_components.p, {
          children: "Díky našemu partnerství a pobočkám po celé Evropě, jsme schopni pravidělně\npřipravit aukci nejen s českým, ale i zahraničním uměním."
        })
      }), "\n", _jsx(Item, {
        title: "Široká nabídka služeb",
        icon: ServicesIcon,
        children: _jsx(_components.p, {
          children: "Spolu s aukčním portálem můžete využít řadu spojených služeb jako je například\ntransport uměleckých děl, posouzení a ocenění díla, nebo naše poradenské\nslužby."
        })
      }), "\n", _jsx(Item, {
        title: "Přijímáme kryptoměny",
        icon: CryptoIcon,
        children: _jsx(_components.p, {
          children: "U nás můžete platit nejen hotově a bankovním převodem v několika měnách, ale\ntaky pomocí kryptoměn, jako jsou například BTC, ETH, LTC, XRP a další."
        })
      }), "\n", _jsx(Item, {
        title: "Komisní i po-aukční prodej",
        icon: SellIcon,
        children: _jsx(_components.p, {
          children: "Mimo online aukci uměleckých děl, Vám nabízíme i možnost nakupovat vystavená\ndíla v poaukčním a komisním prodeji za pevně stanovenou cenu."
        })
      }), "\n", _jsx(Item, {
        title: "Nejnižší provize na trhu",
        icon: PercentIcon,
        children: _jsx(_components.p, {
          children: "Prodávající u nás platí nejnižší aukční provizi na trhu odvíjející se od\nprodejní ceny díla, maximálně však 10%."
        })
      }), "\n", _jsx(Item, {
        title: "VIP privátní aukce",
        icon: VipIcon,
        children: _jsx(_components.p, {
          children: "AIG Vám nabídne možnost zúčastnit se privátních aukcí o vybraná hodnotná\numělecká díla."
        })
      })]
    });
  }
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
