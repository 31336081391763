import { Trans } from "@lingui/macro"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { wrap } from "popmotion"
import { useState } from "react"

import { useRecommendedAuctionsQuery } from "@/domains/auctions/query"
import { styled } from "@/stitches"
import { Button } from "@/ui/button"
import { Grid } from "@/ui/grid"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"

import { Carousel } from "./carousel"

const BackgroundBlur = styled(motion.div, {
  position: "absolute",
  inset: -80,
  backgroundPosition: "center",
  backgroundSize: "cover",
  filter: "blur(80px)",
})

export default function Home({
  title,
  subtitle,
  enableBackgroundBlur = false,
}: {
  title?: string
  subtitle?: string
  enableBackgroundBlur?: boolean
}) {
  const auctions = useRecommendedAuctionsQuery()
  const [page, setPage] = useState<number>(0)
  const bgAuction = auctions.data
    ? auctions.data[wrap(0, auctions.data.length ?? 0, page)]
    : undefined
  const bgImage = bgAuction?.artwork.images[0]?.file.url

  return (
    <Grid
      columns={{ "@initial": 1, "@lg": 2 }}
      columnGap={6}
      css={{
        minHeight: "100vh",
        paddingTop: "var(--menu-height)",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {enableBackgroundBlur && (
        <AnimatePresence>
          {bgImage && (
            <BackgroundBlur
              key={bgImage}
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.2 }}
              exit={{ opacity: 0 }}
              style={{ backgroundImage: `url('${bgImage}')` }}
            />
          )}
        </AnimatePresence>
      )}
      <Stack
        direction="column"
        gap={4}
        css={{
          placeSelf: "center",
          px: "$6",
          py: "$6",
          zIndex: 10,
          "@md": {
            px: "$12",
          },
          "@lg": {
            maxWidth: 800,
          },
        }}
      >
        {title && (
          <Text
            as="h1"
            size={{ "@initial": "5xl", "@lg": "6xl" }}
            weight="bold"
          >
            {title}
          </Text>
        )}
        {subtitle && (
          <Text as="p" size={{ "@initial": "xl", "@md": "2xl" }} color="muted">
            {subtitle}
          </Text>
        )}
        <Stack gap={4} css={{ mt: "$6", mb: "$12" }} wrap>
          <Link href="/auctions" passHref>
            <Button as="a">
              <Trans id="home.heroSection.currentAuctionButton.label">
                Aktuální aukce
              </Trans>
            </Button>
          </Link>
          <Link href="https://my.matterport.com/show/?m=LftgpibtRCR" passHref>
            <Button as="a" variant="outline">
              Virtuální prohlídka
            </Button>
          </Link>
        </Stack>
      </Stack>
      {auctions.data && (
        <Carousel auctions={auctions.data} onPageChange={setPage} />
      )}
    </Grid>
  )
}
