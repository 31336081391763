import { Trans } from "@lingui/macro"
import Link from "next/link"

import { Box } from "@/ui/box"
import { Button } from "@/ui/button"
import { Container } from "@/ui/container"
import { Grid } from "@/ui/grid"
import { Stack } from "@/ui/stack"
import { Text, Title } from "@/ui/text"

export function MakeOffer() {
  return (
    <Box
      as="section"
      css={{
        bg: "$gray2",
        py: "$12",
        "@md": {
          py: "$24",
        },
      }}
    >
      <Container>
        <Grid columns={12}>
          <Stack
            direction="column"
            gap={6}
            css={{
              gridColumn: "span 12",
              "@md": {
                gridColumn: "span 8",
              },
              "@lg": {
                gridColumn: "span 6",
              },
            }}
          >
            <Box>
              <Title as="h2" css={{ mb: "$2" }}>
                <Trans id="home.offerArtworkSection.title">
                  Nabídnout dílo
                </Trans>
              </Title>
              <Text
                size={{
                  "@initial": "3xl",
                  "@md": "4xl",
                }}
                weight="bold"
                as="h2"
              >
                <Trans id="home.offerArtworkSection.subtitle">
                  Nabídněte dílo do aukce
                </Trans>
              </Text>
            </Box>
            <Stack direction="column" gap={2}>
              <Text as="p" size="xl" color="muted">
                <Trans id="home.offerArtworkSection.content.0">
                  Máte zájem prodat dílo, nebo zjistit jeho cenu? Do aukčního i
                  komisního prodeje přijímáme malby, kresby, grafiky, plastiky a
                  sochy.
                </Trans>
              </Text>
              <Text as="p" size="xl" color="muted">
                <Trans id="home.offerArtworkSection.content.1">
                  Kontaktujte nás pomocí formuláře, kde vyplníte základní
                  informace o díle včetně přiložení jeho fotek a Vaše kontaktní
                  údaje.
                </Trans>
              </Text>
            </Stack>
            <Link href="/offer-artwork" passHref>
              <Button as="a" css={{ alignSelf: "flex-start" }}>
                <Trans id="home.offerArtworkSection.offerArtworkButton.label">
                  Nabídnout dílo
                </Trans>
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Container>
    </Box>
  )
}
