import { range } from "fp-ts/NonEmptyArray"
import { motion } from "framer-motion"

import { AuctionCard, AuctionCardSkeleton } from "@/domains/auctions/view/card"
import { styled } from "@/stitches"
import { Slider } from "@/ui/slider"
import { Stack } from "@/ui/stack"

import { AuctionPreview } from "../model"

const SliderItem = styled(motion.li, {
  minWidth: "20rem",
  "@sm": {
    minWidth: "25rem",
  },
})

const listVariants = {
  initial: { opacity: 0 },
  loading: { opacity: 1 },
  loaded: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
}

export function AuctionsSlider({
  auctions,
}: {
  auctions?: Array<AuctionPreview>
}) {
  return (
    <Slider>
      <Stack
        gap={6}
        as={motion.ol}
        initial="initial"
        animate={auctions ? "loaded" : "loading"}
        variants={listVariants}
      >
        {auctions
          ? auctions.map((auction) => (
              <SliderItem
                key={auction.id}
                variants={{
                  initial: { opacity: 0, y: 40 },
                  loaded: { opacity: 1, y: 0 },
                }}
              >
                <AuctionCard auction={auction} />
              </SliderItem>
            ))
          : range(1, 3).map((index) => (
              <SliderItem
                key={index}
                variants={{
                  initial: { opacity: 0 },
                  loading: { opacity: 1 },
                }}
              >
                <AuctionCardSkeleton />
              </SliderItem>
            ))}
      </Stack>
    </Slider>
  )
}
