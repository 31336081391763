import { sourceLocale } from "lingui.config"
import { GetStaticProps } from "next"
import { useRouter } from "next/router"
import { useEffect } from "react"

import { createAppApi } from "@/domains/app/api"
import { createClient } from "@/lib/http"
import { loadLocale } from "@/lib/i18n"
import { Layout } from "@/views/_layout"

import { openDialog } from "../_layout/dialog-store"
import { AuctionRecords } from "./auction-records"
import Hero from "./hero"
import { MakeOffer } from "./make-offer"
import { Values } from "./values"

type HomePageProps = {
  hero: {
    title?: string
    subtitle?: string
  }
}

export const getStaticProps: GetStaticProps<HomePageProps> = async ({
  locale = sourceLocale,
}) => {
  loadLocale(locale)

  const client = createClient()
  const api = createAppApi(client)
  const appConfig = await api.getConfig()

  return {
    props: {
      hero: {
        title: appConfig.appSettings.homepageMainSection.title[locale] ?? "",
        subtitle:
          appConfig.appSettings.homepageMainSection.subtitle[locale] ?? "",
      },
    },
    revalidate: 60,
  }
}

export default function HomePage({ hero }: HomePageProps) {
  const router = useRouter()

  useEffect(() => {
    if (router.query.verifyEmailToken) {
      openDialog("login")
    }
  }, [router.query])

  return (
    <Layout absoluteHeader>
      <Hero {...hero} />
      <Values />
      <AuctionRecords />
      <MakeOffer />
    </Layout>
  )
}
